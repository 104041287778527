import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, ITemplatesListSides } from '@forma/forma-ui-kit';
import SideBlock from '../DistributeSideGroup';
import { DistributeSideItemPlaceholder } from '../DistributeSideItem';

import styles from './distribute-sides-groups.module.css';

interface SortSideItem {
  templateId: string,
  sideIndex: number
}

interface DistributeSidesGroupsProps {
  isLoading?: boolean,
  sides: { name: string, sideIndices: SortSideItem[] }[],
  templatesSides?: ITemplatesListSides,
  onAddSide: () => void,
  onRemoveSide: (index: number) => void,
  onRenameSide: (index: number, name: string) => void
}

const DistributeSidesGroups: FC<DistributeSidesGroupsProps> = ({
  isLoading, sides, templatesSides, onAddSide, onRemoveSide, onRenameSide
}) => {
  return (
    <div className={styles.root}>
      <div id="templates_pack_fill_controls" className={classNames(styles.folders, 'styled-scrollbar')}>
        {isLoading ? (
          [...Array(4).keys()].map(key => (
            <div className={styles.item} key={key}>
              <DistributeSideItemPlaceholder />
            </div>
          ))
        ) : (
          sides?.map(({ name, sideIndices }, index) => (
            <SideBlock
              index={index}
              name={name}
              templatesSides={templatesSides}
              sideIndices={sideIndices}
              onRemove={onRemoveSide}
              onRename={onRenameSide}
              key={index}
            />
          ))
        )}
      </div>
      <div className={styles.buttons}>
        <Button
          id="add_side_button"
          viewStyle="text"
          className={styles.addButton}
          iconClassName={styles.addButtonIcon}
          fullWidth
          icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
          onClick={onAddSide}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default DistributeSidesGroups;
