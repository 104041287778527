import { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useDraggable } from '@dnd-kit/core';
import { ITemplateSide } from '@forma/forma-ui-kit';

import styles from './distribute-side-item.module.css';

interface DistributeSideItemProps {
  fromSide?: number,
  templateId: string,
  sideIndex: number,
  templateName: string,
  side?: ITemplateSide,
}

const DistributeSideItem: FC<DistributeSideItemProps> = ({
  fromSide, templateId, sideIndex, templateName, side
}) => {
  const { t } = useTranslation();
  const [ isOpen, setOpen ] = useState<boolean>(false);

  const name = side ? side.name : t('deleted_side');

  const { attributes, listeners, setNodeRef: dragRef, isDragging } = useDraggable({
    id: `${templateId}_${sideIndex}`,
    data: { fromSide, templateId, sideIndex, templateName, name }
  });
  const style = {
    opacity: isDragging ? 0 : 1
  };

  return (
    <div
      className={classNames(styles.root, isDragging && styles.dragging)}
      style={style}
      ref={dragRef}
    >
      <div className={styles.header} onClick={() => setOpen(!isOpen)}>
        <div className={styles.title}>
          {side ? name : <span className={styles.deleted}>{name}</span>}
          <span className={styles.templateName}>
            <ReactSVG src="/icons/arrow-left.svg" />
            {templateName}
          </span>
        </div>
        <ReactSVG src="/icons/dropdown.svg" wrapper="span" className={classNames(styles.arrow, isOpen && styles.open)} />
      </div>
      {isOpen && (
        <div className={styles.content}>
          {side?.tattrFolders.map(({ id, name, tattrs }) => !tattrs.length ? null : (
            <div className={styles.folder} key={id}>
              <div className={styles.folderName}>{name}</div>
              <div className={styles.folderTattrs}>
                {tattrs.map(({ id, name }) => (
                  <div className={styles.tattrName} key={id}>{name}</div>
                ))}
              </div>
            </div>
          ))}
          {!!(side?.carticles?.length) && (
            <div className={styles.folder}>
              <div className={styles.folderName}>{t('variations_blocks')}</div>
              <div className={styles.folderTattrs}>
                {side?.carticles.map(({ id, title }) => (
                  <div className={styles.tattrName} key={id}>{title}</div>
                ))}
              </div>
            </div>
          )}
          {!!(side?.tables?.length) && (
            <div className={styles.folder}>
              <div className={styles.folderName}>{t('tables')}</div>
              <div className={styles.folderTattrs}>
                {side.tables.map(({ id, name }) => (
                  <div className={styles.tattrName} key={id}>{name}</div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      <div className={styles.handler} {...listeners} {...attributes}>
        <ReactSVG src="/icons/dots-move.svg" wrapper="span" />
      </div>
    </div>
  );
};

export default DistributeSideItem;
